import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, Typography, List, ListItem, ListItemText, TextField, Box, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, Tooltip, CircularProgress, MenuItem } from '@mui/material';
import { Button, ButtonGroup } from '@mui/material';
import ColorScheme from '../../Styles/ColorScheme';
import { ReactComponent as ArrowUp } from "../../Assets/arrow_up.svg";
import { ReactComponent as ArrowDown } from "../../Assets/arrow_down.svg";
import { ReactComponent as EditIcon } from "../../Assets/pen_edit.svg";
import { ReactComponent as DownloadIcon } from "../../Assets/download_icon.svg";
import { ReactComponent as InvoiceIcon } from "../../Assets/invoice_icon.svg";
import { ReactComponent as SparkleIcon } from "../../Assets/sparkle_icon.svg";
import { useUser } from '../../Utils/UserContext';
import ContractDialog from './Contract';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import { sofiaSansFont } from './sofia-sans-base64'
import { logo } from './logo-base64';



const ContractList = () => {
    const [contracts, setContracts] = useState([]);
    const { user, loading } = useUser();
    const [expanded, setExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'customerName', direction: 'asc' });
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedContract, setSelectedContract] = useState(null);

    const [invoicePage, setInvoicePage] = useState(1);

    const [contractRows, setContractRows] = useState({});
    const [contractInvoices, setContractInvoices] = useState({});
    const [totalInvoicePages, setTotalInvoicePages] = useState({})
    const [loadingContracts, setLoadingContracts] = useState(false);
    const [loadingInvoices, setLoadingInvoices] = useState(false);
    const [loadingRows, setLoadingRows] = useState(false);

    const isTabletOrBelow = useMediaQuery('(max-width:768px)');

    const isFirstRender = useRef(true);
    const searchTimeoutRef = useRef(null);

    const handleAccordionChange = async (contractId) => {
        setInvoicePage(1);
        setLoadingInvoices(true);
        setLoadingRows(true);
        if (expanded === contractId) {
            setExpanded(false);
            setLoadingInvoices(false);
            setLoadingRows(false);
        } else {
            setExpanded(contractId);
            if (!contractRows[contractId] || !contractInvoices[contractId]) {
                try {
                    const contract = contracts.find(c => c.getId() === contractId);
                    const rows = await contract.getRows();
                    const invoices = await contract.getInvoices(invoicePage); // Fetch invoices
                    setContractRows(prevState => ({ ...prevState, [contractId]: rows }));
                    setContractInvoices(prevState => ({ ...prevState, [contractId]: invoices.invoices })); // Update state with invoices
                    setTotalInvoicePages(prevState => ({ ...prevState, [contractId]: invoices.totalPages }));
                    setLoadingInvoices(false);
                    setLoadingRows(false);
                } catch (error) {
                    console.error("Failed to fetch rows or invoices:", error);
                    setLoadingInvoices(false);
                    setLoadingRows(false);
                }
            }
            else {
                setLoadingInvoices(false);
                setLoadingRows(false);
            }
        }
    };

    useEffect(() => {
        if (expanded) {
            setLoadingInvoices(true);
            const contract = contracts.find(c => c.getId() === expanded);
            contract.getInvoices(invoicePage)
                .then((response) => {
                    setContractInvoices(prevState => ({ ...prevState, [contract.getId()]: response.invoices }));
                    setTotalInvoicePages(prevState => ({ ...prevState, [contract.getId()]: response.totalPages }));
                    setLoadingInvoices(false);
                })
                .catch((error) => {
                    console.error("Failed to fetch invoices:", error);
                    setLoadingInvoices(false);
                });

        }
    }, [invoicePage]);

    useEffect(() => {
        setExpanded(false);
        if (!loading && user) {
            if (isFirstRender.current) {
                // Fetch contracts only once on the first render
                fetchContracts();
                isFirstRender.current = false;
            } else {
                if (searchTerm) {
                    if (searchTimeoutRef.current) {
                        clearTimeout(searchTimeoutRef.current);
                    }
                    searchTimeoutRef.current = setTimeout(() => {
                        fetchContracts();
                    }, 1000); // 1 second delay
                } else {
                    fetchContracts();
                }
            }
        }
    }, [page, sortConfig, searchTerm, pageSize]);

    useEffect(() => {
        if (sortConfig || searchTerm) {
            setPage(1);
        }
    }, [sortConfig, searchTerm]);

    const fetchContracts = () => {
        setLoadingContracts(true);
        const { key, direction } = sortConfig;
        user.getContracts({ sortField: key, sortOrder: direction, searchTerm, page, limit: pageSize })
            .then((response) => {
                setContracts(response.contracts);
                setTotalPages(response.totalPages);
                setLoadingContracts(false);
            })
            .catch((error) => {
                console.log(error);
                setLoadingContracts(false);
            });
    };

    const handlePageChange = (event, value) => {
        setExpanded(false);
        setPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSort = (property) => {
        let direction = 'asc';
        if (sortConfig.key === property && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key: property, direction });
    };

    const handleDialogOpen = (contract = null) => {
        setSelectedContract(contract);
        setDialogOpen(true);
    };

    const handleDialogClose = async () => {
        setDialogOpen(false);

        if (selectedContract) {
            setLoadingInvoices(true);
            setLoadingRows(true);
            try {
                // Fetch updated contract data
                const updatedContract = await user.getContractById(selectedContract.getId());
                const updatedRows = await updatedContract.getRows();
                const updatedInvoices = await updatedContract.getInvoices(invoicePage);

                // Update contracts state
                setContracts(prevContracts => prevContracts.map(contract =>
                    contract.getId() === updatedContract.getId() ? updatedContract : contract
                ));

                // Update contractRows state
                setContractRows(prevState => ({ ...prevState, [updatedContract.getId()]: updatedRows }));

                // Update contractInvoices state
                setContractInvoices(prevState => ({ ...prevState, [updatedContract.getId()]: updatedInvoices.invoices }));
                setLoadingInvoices(false);
                setLoadingRows(false);
            } catch (error) {
                console.error("Failed to fetch updated contract data:", error);
                setLoadingInvoices(false);
                setLoadingRows(false);
            }
        }

        setSelectedContract(null);
    };

    const handleSave = () => {
        fetchContracts();
    };


    const renderInvoiceRows = (invoice) => {
        return (
            <Box>
                {invoice.getInvoiceRows().map((row, index) => (
                    <Typography color={ColorScheme.black} key={index} variant='body1'>
                        {row.description}: {row.price} €
                    </Typography>
                ))}
            </Box>
        );
    };

    const renderContractStatus = (contract) => {
        if (contract.getEndingDate() == null) {
            return <Box sx={{ display: "flex", flexDirection: "column" }}>

                <Typography color={ColorScheme.black} variant='h6' textAlign={"right"}>
                    {(new Date(contract.getStartingDate()) < new Date() ? "Alkanut: " : "Alkaa: ") + new Date(contract.getStartingDate()).toLocaleDateString()}
                </Typography>
                <Typography color={ColorScheme.black} variant="h6" textAlign={"right"}>Toistaiseksi voimassa</Typography>
            </Box>;
        } else if (new Date(contract.getEndingDate()) < new Date()) {
            return <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography color={ColorScheme.black} variant='h6' textAlign={"right"}>
                    {(new Date(contract.getStartingDate()) < new Date() ? "Alkoi: " : "Alkaa: ") + new Date(contract.getStartingDate()).toLocaleDateString()}
                </Typography>
                <Typography color={ColorScheme.black} variant='h6' textAlign={"right"}>
                    {"Päättyi: " + new Date(contract.getEndingDate()).toLocaleDateString()}
                </Typography>

            </Box>;
        } else {
            return <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography color={ColorScheme.black} variant='h6' textAlign={"right"}>
                    {(new Date(contract.getStartingDate()) < new Date() ? "Alkanut: " : "Alkaa: ") + new Date(contract.getStartingDate()).toLocaleDateString()}
                </Typography>
                <Typography color={ColorScheme.black} variant='h6' textAlign={"right"}>
                    {"Päättyy: " + new Date(contract.getEndingDate()).toLocaleDateString()}
                </Typography>
            </Box>;
        }

    };

    const loadSofiaSansFont = (doc) => {
        doc.addFileToVFS('SofiaSans.ttf', sofiaSansFont);
        doc.addFont('SofiaSans.ttf', 'Sofia Sans', 'normal');
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}${month}${day}`;
    };

    const generatePDF = (contract) => {
        const rows = contractRows[contract.getId()] || [];

        const startingDate = new Date(contract.getStartingDate());
        const formattedDate = formatDate(startingDate);

        const doc = new jsPDF();
        loadSofiaSansFont(doc);
        doc.setFont('Sofia Sans');

        // Add logo to the top-left corner
        doc.addImage(logo, 'PNG', 10, 10, 53, 9.02); // Adjust the position and size as needed

        // Add "LUOTTAMUKSELLINEN" text
        doc.setFontSize(11);
        doc.text('LUOTTAMUKSELLINEN', 100, 14, null, null, 'left');
        doc.text('SOPIMUS', 100, 19, null, null, 'left');

        // Add page number and date to the right top corner
        const pageCount = doc.internal.getNumberOfPages();
        const today = new Date().toLocaleDateString();
        doc.text(`Sivu ${pageCount}`, 200, 14, null, null, 'right');
        doc.text(today, 200, 19, null, null, 'right');

        // Add separator below the header
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0, 0.2); // Black with 0.2 opacity
        doc.line(10, 30, 200, 30);


        // Your own info in the left top corner
        doc.setFontSize(16);
        doc.text('Tilaaja', 10, 40);
        doc.setFontSize(11);
        doc.text(`${contract.getCustomer().getName()}`, 10, 50);
        doc.text(`${contract.getCustomer().getBusinessId()}`, 10, 55);
        doc.text(`${contract.getCustomer().getAddress()}`, 10, 65);
        doc.text(`${contract.getCustomer().getPostalCode()}, ${contract.getCustomer().getCity()}`, 10, 70);

        doc.text(`${contract.getCustomer().getAdditionalInformation()}`, 40, 80);
        // Customer info in the right top corner

        doc.text('Yhteyshenkilö:', 100, 50);
        doc.text('Organisaatiotunnus:', 100, 70);
        doc.text('Maksuehto:', 100, 75);
        doc.text('Myyjä:', 100, 80);

        doc.text(`${contract.getCustomer().getContactName()}`, 140, 50);
        doc.text(`${contract.getCustomer().getEmail()}`, 140, 55);
        doc.text(`${contract.getCustomer().getPhone()}`, 140, 60);
        doc.text(`${contract.getCustomer().getOrganizationIdentifier()}`, 140, 70);
        doc.text('14 pv netto', 140, 75);
        doc.text(`${user.getEmail()}`, 140, 80);

        // Contract description
        doc.setFontSize(16);
        doc.text('Kuvaus', 10, 100);
        doc.setFontSize(11);
        const description = contract.getDescription();
        const descriptionLines = doc.splitTextToSize(description, 190);
        const descriptionHeight = descriptionLines.length * 5; // Assuming each line is approximately 10 units high

        doc.text(description, 10, 110, { maxWidth: 190 });

        const descriptionY = 110 + descriptionHeight;
        doc.text('Sopimus alkaen: ' + startingDate.toLocaleDateString(), 10, descriptionY + 2);
        doc.text('Laskutusjakso: ' + contract.getInvoicingInterval() + ' kk, etupainotteinen', 10, descriptionY + 7);

        // Contract rows using jsPDF-AutoTable
        doc.autoTable({
            startY: descriptionY + 15, // Start the table after the description
            head: [['Kuvaus',
                { content: 'Määrä', styles: { halign: 'right' } },
                { content: 'Yks.', styles: { halign: 'right' } },
                { content: 'À-hinta', styles: { halign: 'right' } },
                { content: 'Ale-%', styles: { halign: 'right' } },
                { content: 'Rivisumma', styles: { halign: 'right' } }
            ]],
            body: rows.map(row => [
                row.getDescription(),
                { content: row.getAmount(), styles: { halign: 'right' } },
                { content: row.getUnit(), styles: { halign: 'right' } },
                { content: row.getUnitPrice(), styles: { halign: 'right' } },
                { content: row.getDiscountPercentage(), styles: { halign: 'right' } },
                { content: row.getPrice(), styles: { halign: 'right' } }
            ]),
            theme: 'grid',
            styles: { font: 'Sofia Sans' },
            headStyles: {
                fillColor: ColorScheme.primary,
            },
            tableWidth: '100%',
            margin: { left: 10, right: 10 }
        });

        // Calculate totals
        const vat = contract.getTotalPrice() * 0.255; // Assuming 25.5% VAT
        const totalDue = contract.getTotalPrice() + vat;

        // Add totals below the table
        const finalY = doc.autoTable.previous.finalY || descriptionY + 10;
        doc.setFontSize(10);
        doc.line(100, finalY + 5, 200, finalY + 5);
        doc.text('Hinta ilman alv:a', 100, finalY + 10, null, null, 'left');
        doc.text('Alv (25,5 %)', 100, finalY + 15, null, null, 'left');
        doc.text('Yhteensä sis. alv 25,5 %', 100, finalY + 20, null, null, 'left');

        doc.text(`${contract.getTotalPrice().toFixed(2)} €`, 200, finalY + 10, null, null, 'right');
        doc.text(`${vat.toFixed(2)} €`, 200, finalY + 15, null, null, 'right');
        doc.text(`${totalDue.toFixed(2)} €`, 200, finalY + 20, null, null, 'right');

        doc.setFontSize(11);

        const pageHeight = doc.internal.pageSize.height;
        doc.setLineWidth(0.5);
        doc.setDrawColor(0, 0, 0, 0.2); // Black with 0.2 opacity
        doc.line(10, pageHeight - 30, 200, pageHeight - 30);

        // Add footer
        doc.setFontSize(10);
        doc.text('Tolotech Oy', 10, pageHeight - 20);
        doc.text('Pyynikintie 25', 10, pageHeight - 15);
        doc.text('33230 Tampere', 10, pageHeight - 10);
        doc.text('Y-tunnus: 1648255-0', 105, pageHeight - 15, null, null, 'center');
        doc.text('www.tolotech.fi', 105, pageHeight - 10, null, null, 'center');
        doc.text('myynti@tolotech.fi', 200, pageHeight - 15, null, null, 'right');
        doc.text('Puh. 03 4518 0580', 200, pageHeight - 10, null, null, 'right');

        doc.save(`sopimus_${contract.getCustomer().getName()}_${formattedDate}.pdf`);
    };


    return (
        <Container sx={{ paddingBottom: "20px", paddingTop: "10px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>


                <Typography variant="h2" gutterBottom>
                    Sopimukset
                </Typography>


                <Button
                    variant="contained"
                    sx={{ backgroundColor: ColorScheme.primary, color: ColorScheme.white, textTransform: "none" }}
                    onClick={() => handleDialogOpen()}
                >
                    <Typography variant='h6'>
                        {"+ Lisää sopimus"}
                    </Typography>
                </Button>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "row", mt: "20px", justifyContent: "space-between", alignItems: "flex-end", width: "100%" }}>
                <TextField
                    size='small'
                    sx={{ backgroundColor: ColorScheme.search, borderRadius: "4px", width: "89%" }}
                    label="Etsi"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <TextField
                    sx={{ width: "10%", borderRadius: "4px" }}
                    select
                    size='small'
                    label="Näytä"
                    value={pageSize}
                    onChange={(event) => setPageSize(event.target.value)}
                    variant="outlined"
                >
                    {[10, 25, 50, 100].map((size) => (
                        <MenuItem key={size} value={size}>
                            {size}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box sx={{ width: "100%" }}>
                <ButtonGroup fullWidth variant="outlined" sx={{
                    my: "10px", ".MuiButtonGroup-grouped": {
                        borderColor: ColorScheme.border,
                    },
                }}>
                    <Button
                        onClick={() => handleSort('customerName')}
                        sx={{
                            borderColor: "red",
                            backgroundColor: sortConfig.key === 'customerName' ? ColorScheme.primary : ColorScheme.lightGrey,
                            color: sortConfig.key === 'customerName' ? ColorScheme.white : ColorScheme.primary,
                            '&:hover': {
                                backgroundColor: sortConfig.key === 'customerName' ? ColorScheme.primary : 'transparent',
                            },
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant='h6' textTransform={"none"}>
                            Nimi
                        </Typography>
                        {sortConfig.key === 'customerName' ? (
                            sortConfig.direction === 'asc' ? <ArrowUp height={15} stroke={ColorScheme.white} /> : <ArrowDown height={15} stroke={ColorScheme.white} />
                        ) : <ArrowUp height={15} stroke={ColorScheme.primary} />}

                    </Button>
                    <Button
                        onClick={() => handleSort('nextInvoicesDate')}
                        sx={{
                            backgroundColor: sortConfig.key === 'nextInvoicesDate' ? ColorScheme.primary : ColorScheme.lightGrey,
                            color: sortConfig.key === 'nextInvoicesDate' ? ColorScheme.white : ColorScheme.primary,
                            '&:hover': {
                                backgroundColor: sortConfig.key === 'nextInvoicesDate' ? ColorScheme.primary : 'transparent',
                            },
                        }}
                    >
                        <SparkleIcon height={"10px"} stroke={sortConfig.key === 'nextInvoicesDate' ? ColorScheme.white : ColorScheme.primary} />
                        <Typography variant='h6' textTransform={"none"}>
                            Seuraava lasku
                        </Typography>
                        {sortConfig.key === 'nextInvoicesDate' ? (
                            sortConfig.direction === 'asc' ? <ArrowUp height={15} stroke={ColorScheme.white} /> : <ArrowDown height={15} stroke={ColorScheme.white} />
                        ) : <ArrowUp height={15} stroke={ColorScheme.primary} />}

                    </Button>
                    <Button
                        onClick={() => handleSort('startingDate')}
                        sx={{
                            backgroundColor: sortConfig.key === 'startingDate' ? ColorScheme.primary : ColorScheme.lightGrey,
                            color: sortConfig.key === 'startingDate' ? ColorScheme.white : ColorScheme.primary,
                            '&:hover': {
                                backgroundColor: sortConfig.key === 'startingDate' ? ColorScheme.primary : 'transparent',
                            },
                        }}
                    >
                        <SparkleIcon height={"10px"} stroke={sortConfig.key === 'startingDate' ? ColorScheme.white : ColorScheme.primary} />
                        <Typography variant='h6' textTransform={"none"}>
                            Alkanut
                        </Typography>
                        {sortConfig.key === 'startingDate' ? (
                            sortConfig.direction === 'asc' ? <ArrowUp height={15} stroke={ColorScheme.white} /> : <ArrowDown height={15} stroke={ColorScheme.white} />
                        ) : <ArrowUp height={15} stroke={ColorScheme.primary} />}
                    </Button>
                    <Button
                        onClick={() => handleSort('endingDate')}
                        sx={{
                            backgroundColor: sortConfig.key === 'endingDate' ? ColorScheme.primary : ColorScheme.lightGrey,
                            color: sortConfig.key === 'endingDate' ? ColorScheme.white : ColorScheme.primary,
                            '&:hover': {
                                backgroundColor: sortConfig.key === 'endingDate' ? ColorScheme.primary : 'transparent',
                            },
                        }}
                    >
                        <SparkleIcon height={"10px"} stroke={sortConfig.key === 'endingDate' ? ColorScheme.white : ColorScheme.primary} />
                        <Typography variant='h6' textTransform={"none"}>
                            Päättynyt
                        </Typography>
                        {sortConfig.key === 'endingDate' ? (
                            sortConfig.direction === 'asc' ? <ArrowUp height={15} stroke={ColorScheme.white} /> : <ArrowDown height={15} stroke={ColorScheme.white} />
                        ) : <ArrowUp height={15} stroke={ColorScheme.primary} />}
                    </Button>
                </ButtonGroup>
            </Box>
            <Box sx={{ border: 1, borderColor: ColorScheme.border, borderRadius: "4px", backgroundColor: ColorScheme.lightGrey }}>
                {!loadingContracts ? <List sx={{ p: "5px" }}>
                    {contracts.map((contract) => {
                        return <ListItem key={contract.getId()} sx={{ p: 0 }} >
                            <Accordion
                                elevation={0}
                                sx={{ flexDirection: "column", width: "100%", backgroundColor: expanded === contract.getId() ? ColorScheme.white : ColorScheme.lightGrey }}
                                expanded={expanded === contract.getId()}
                                onChange={() => handleAccordionChange(contract.getId())}>
                                <AccordionSummary sx={{ width: "100%", paddingRight: 0 }}>
                                    <Box sx={{ flexDirection: "row", display: "flex", width: "100%", justifyContent: "space-between" }}>
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center", marginRight: "20px" }}>

                                                <Box
                                                    sx={{
                                                        width: 10,
                                                        height: 10,
                                                        borderRadius: '50%',
                                                        backgroundColor: contract.getEndingDate() == null ? ColorScheme.green : (new Date(contract.getEndingDate()) < new Date() ? ColorScheme.red : ColorScheme.yellow),
                                                    }}
                                                />
                                            </Box>

                                            <Box sx={{ width: "150px", flexDirection: "column", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                <Typography variant='h4' sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: "100%" }}>
                                                    {contract.getCustomer().getName()}
                                                </Typography>
                                                <Typography variant='body2' sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: "100%" }}>
                                                    {contract.getCustomer().getAddress() + ", " + contract.getCustomer().getPostalCode() + " " + contract.getCustomer().getCity()}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ alignItems: "center", pr: "10px", pl: "5px", py: "5px", ml: "20px", flexDirection: "row", display: "flex", borderRadius: "4px", border: "1px solid rgba(0, 0, 0, 0.1)" }}>
                                                <InvoiceIcon height={"20px"} stroke={ColorScheme.primary} />

                                                <Typography color={ColorScheme.primary} variant='h5' sx={{ mt: "2px", ml: "5px" }}>
                                                    {(contract.getNextInvoicesDate() && new Date(contract.getNextInvoicesDate()) > new Date()) ? new Date(contract.getNextInvoicesDate()).toLocaleDateString() : "-"}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row" }}>

                                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>

                                                <Box sx={{ flexDirection: "row", display: "flex" }}>

                                                    {renderContractStatus(contract)}
                                                    <Button sx={{ mx: "15px", padding: 0, minWidth: 0 }} onClick={(event) => {
                                                        event.stopPropagation()
                                                        handleDialogOpen(contract)
                                                    }} >
                                                        <EditIcon height={"20px"} stroke={ColorScheme.primary} />
                                                    </Button>

                                                </Box>

                                            </Box>
                                        </Box>


                                    </Box>

                                </AccordionSummary>
                                <AccordionDetails sx={{}}>
                                    <Button variant='outlined' sx={{
                                        borderColor: ColorScheme.border,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: ColorScheme.lightGrey,
                                        color: ColorScheme.primary,
                                        marginBottom: "20px",
                                        width: "100%",
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                        },
                                    }} onClick={(event) => {
                                        event.stopPropagation();
                                        generatePDF(contract);
                                    }}>
                                        <Typography color={ColorScheme.primary} textTransform={"none"} variant='h6'>
                                            {"Lataa PDF"}
                                        </Typography>
                                        <DownloadIcon height={"20px"} stroke={ColorScheme.primary} />
                                    </Button>
                                    <Box sx={{ display: "flex", flexDirection: isTabletOrBelow ? "column" : "row", justifyContent: "space-between" }}>
                                        <Box sx={{ width: isTabletOrBelow ? "100%" : "48%" }}>
                                            {isTabletOrBelow && <Typography variant='h4' sx={{ marginBottom: "20px" }}>
                                                {"Sopimuksen rivit"}
                                            </Typography>}
                                            {!loadingRows ? (contractRows[contract.getId()] && contractRows[contract.getId()].map((row, index) => {
                                                return <Box key={row.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, borderTop: index == 0 && '1px solid ' + ColorScheme.border, borderBottom: '1px solid ' + ColorScheme.border }}>
                                                    <Box sx={{ width: "90%", flexDirection: "row", display: "flex" }}>
                                                        <Box sx={{ marginRight: "10px", border: "1px solid " + ColorScheme.border, borderRadius: "4px", padding: "10px", backgroundColor: ColorScheme.lightGrey, flexDirection: "column", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                            <Typography variant='body2'>
                                                                {row.getAmount() + " " + row.getUnit()}
                                                            </Typography>
                                                            <Typography variant='h6'>
                                                                {row.getUnitPrice() + " €/" + row.getUnit()}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                                                {row.getDescription()}
                                                            </Typography>
                                                            <Typography variant='body2'>
                                                                {"Alennus: " + row.getDiscountPercentage() + " %"}
                                                            </Typography>
                                                        </Box>

                                                    </Box>
                                                    <Box sx={{ textAlign: 'right', width: "10%" }}>
                                                        <Typography variant='h6'>
                                                            {row.getPrice()} €
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            })) : <Box sx={{ width: "100%", padding: "20px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                <CircularProgress sx={{ color: ColorScheme.primary }} />
                                            </Box>
                                            }
                                        </Box>
                                        <Box sx={{ width: isTabletOrBelow ? "100%" : "48%", borderRadius: "4px" }}>
                                            {isTabletOrBelow && <Typography variant='h4' sx={{ marginBottom: "20px", marginTop: "30px" }}>
                                                {"Lähetetyt laskut"}
                                            </Typography>}
                                            {!loadingInvoices ? (contractInvoices[contract.getId()] && contractInvoices[contract.getId()].map(invoice => {
                                                return <Tooltip key={invoice.id} placement='top' followCursor title={renderInvoiceRows(invoice)} >
                                                    <Box sx={{ display: 'flex', marginBottom: "5px", borderRadius: "4px", justifyContent: 'space-between', alignItems: 'center', p: 1, backgroundColor: invoice.getPaymentStatus() ? ColorScheme.green_opacity : ColorScheme.red_opacity }}>
                                                        <Box>
                                                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                                                {invoice.getTotalDue() + " €"}
                                                            </Typography>
                                                            <Typography variant='body2'>
                                                                {invoice.getDueDate() ? "Eräpäivä " + new Date(invoice.getDueDate()).toLocaleDateString() : "Eräpäivä ei määritelty"}
                                                            </Typography>

                                                        </Box>
                                                        <Box sx={{ textAlign: 'right' }}>
                                                            <Typography variant='h6' color={invoice.getPaymentStatus() ? ColorScheme.green : ColorScheme.red}>
                                                                {invoice.getPaymentStatus() ? "Maksettu" : "Maksamatta"}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Tooltip>

                                            })) : <Box sx={{ width: "100%", padding: "20px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                <CircularProgress sx={{ color: ColorScheme.primary }} />
                                            </Box>

                                            }
                                            {totalInvoicePages[contract.getId()] > 1 && (
                                                <Box sx={{ display: "flex", justifyContent: "center", mt: "20px", flexDirection: "row", alignItems: "center" }}>
                                                    {/*<ButtonGroup fullWidth variant="outlined" sx={{
                                                        ".MuiButtonGroup-grouped": {
                                                            borderColor: ColorScheme.border,
                                                        },
                                                    }}>
                                                        {(() => {
                                                            const pages = Array.from(new Set([
                                                                ...Array.from({ length: 3 }, (_, i) => invoicePage - 1 + i), // current page ± 1
                                                                1, // first page
                                                                totalInvoicePages[contract.getId()] // last page
                                                            ]))
                                                                .filter(pageNr => pageNr > 0 && pageNr <= totalInvoicePages[contract.getId()])
                                                                .sort((a, b) => a - b);

                                                            const result = [];
                                                            for (let i = 0; i < pages.length; i++) {
                                                                result.push(pages[i]);
                                                                if (i < pages.length - 1 && pages[i + 1] > pages[i] + 1) {
                                                                    result.push("...");
                                                                }
                                                            }
                                                            return result;
                                                        })().map((pageNr, index) => (
                                                            pageNr === "..." ? (
                                                                <Button sx={{
                                                                    backgroundColor: pageNr === page ? ColorScheme.primary : ColorScheme.lightGrey,
                                                                    color: pageNr === page ? ColorScheme.white : ColorScheme.primary,
                                                                    '&:hover': {
                                                                        backgroundColor: pageNr === page ? ColorScheme.primary : 'transparent',
                                                                    },
                                                                }} key={`ellipsis-${index}`}>
                                                                    <Typography textTransform={"none"} variant='body2'>
                                                                        ...
                                                                    </Typography>
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    key={pageNr}
                                                                    onClick={(event) => setInvoicePage(pageNr)}
                                                                    sx={{
                                                                        backgroundColor: pageNr === invoicePage ? ColorScheme.primary : ColorScheme.lightGrey,
                                                                        color: pageNr === invoicePage ? ColorScheme.white : ColorScheme.primary,
                                                                        '&:hover': {
                                                                            backgroundColor: pageNr === invoicePage ? ColorScheme.primary : 'transparent',
                                                                        },
                                                                    }}
                                                                >
                                                                    <Typography textTransform={"none"} variant='body2'>
                                                                        {pageNr}
                                                                    </Typography>
                                                                </Button>
                                                            )
                                                        ))}
                                                    </ButtonGroup>*/}

                                                    <Button
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setInvoicePage(invoicePage - 1);
                                                        }}
                                                        disabled={invoicePage === 1}
                                                        variant='outlined'
                                                        sx={{
                                                            borderColor: ColorScheme.border,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            backgroundColor: ColorScheme.lightGrey,
                                                            color: ColorScheme.primary,
                                                            '&:hover': {
                                                                backgroundColor: 'transparent',
                                                            },
                                                        }}
                                                    >
                                                        <Typography textTransform={"none"} variant='body2'>
                                                            Edellinen
                                                        </Typography>
                                                    </Button>
                                                    <Typography variant='body2' sx={{ mx: "20px" }}>
                                                        Sivu {invoicePage} / {totalInvoicePages[contract.getId()]}
                                                    </Typography>
                                                    <Button
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            setInvoicePage(invoicePage + 1);
                                                        }}
                                                        disabled={invoicePage === totalInvoicePages[contract.getId()]}
                                                        variant='outlined'
                                                        sx={{
                                                            borderColor: ColorScheme.border,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            backgroundColor: ColorScheme.lightGrey,
                                                            color: ColorScheme.primary,
                                                            '&:hover': {
                                                                backgroundColor: 'transparent',
                                                            },
                                                        }}
                                                    >
                                                        <Typography textTransform={"none"} variant='body2'>
                                                            Seuraava
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>


                                </AccordionDetails>
                            </Accordion>
                        </ListItem>
                    })}
                </List> : <Box sx={{ width: "100%", padding: "20px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                    <CircularProgress sx={{ color: ColorScheme.primary }} />
                </Box>}
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mt: "20px", flexDirection: "row", alignItems: "center" }}>
                {/*<ButtonGroup fullWidth variant="outlined" sx={{
                    ".MuiButtonGroup-grouped": {
                        borderColor: ColorScheme.border,
                    },
                }}>
                    {(() => {
                        const pages = Array.from(new Set([
                            ...Array.from({ length: 3 }, (_, i) => page - 1 + i), // current page ± 1
                            1, // first page
                            totalPages // last page
                        ]))
                            .filter(pageNr => pageNr > 0 && pageNr <= totalPages)
                            .sort((a, b) => a - b);

                        const result = [];
                        for (let i = 0; i < pages.length; i++) {
                            result.push(pages[i]);
                            if (i < pages.length - 1 && pages[i + 1] > pages[i] + 1) {
                                result.push("...");
                            }
                        }
                        return result;
                    })().map((pageNr, index) => (
                        pageNr === "..." ? (
                            <Button sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: pageNr === page ? ColorScheme.primary : ColorScheme.lightGrey,
                                color: pageNr === page ? ColorScheme.white : ColorScheme.primary,
                                '&:hover': {
                                    backgroundColor: pageNr === page ? ColorScheme.primary : 'transparent',
                                },
                            }} key={`ellipsis-${index}`}>
                                <Typography textTransform={"none"} variant='body1'>
                                    ...
                                </Typography>
                            </Button>
                        ) : (
                            <Button
                                key={pageNr}
                                onClick={(event) => handlePageChange(event, pageNr)}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: pageNr === page ? ColorScheme.primary : ColorScheme.lightGrey,
                                    color: pageNr === page ? ColorScheme.white : ColorScheme.primary,
                                    '&:hover': {
                                        backgroundColor: pageNr === page ? ColorScheme.primary : 'transparent',
                                    },
                                }}
                            >
                                <Typography textTransform={"none"} variant='body1'>
                                    {pageNr}
                                </Typography>
                            </Button>
                        )
                    ))}
                </ButtonGroup>*/}

                <Button
                    onClick={(event) => handlePageChange(event, page - 1)}
                    disabled={page === 1}
                    variant='outlined'
                    sx={{
                        borderColor: ColorScheme.border,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: ColorScheme.lightGrey,
                        color: ColorScheme.primary,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    <Typography textTransform={"none"} variant='body1'>
                        Edellinen
                    </Typography>
                </Button>

                <Typography variant='body1' sx={{ mx: "20px" }}>
                    Sivu {page} / {totalPages}
                </Typography>

                <Button
                    onClick={(event) => handlePageChange(event, page + 1)}
                    disabled={page === totalPages}
                    variant='outlined'
                    sx={{
                        borderColor: ColorScheme.border,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: ColorScheme.lightGrey,
                        color: ColorScheme.primary,
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    <Typography textTransform={"none"} variant='body1'>
                        Seuraava
                    </Typography>
                </Button>
            </Box>


            <ContractDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                contract={selectedContract}
                onSave={handleSave}
            />

        </Container>
    );
};

export default ContractList;
