/* eslint-disable indent */
import React, { createContext, useContext } from "react";

import axios from "axios";

import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";

export const ApiContext = createContext();

export const ApiProvider = ({ children }) => {

    const BASE_URL = "/api/";

    const navigate = useNavigate();

    const ApiCall = (method, url, body, signal) => {

        return new Promise((resolve, reject) => {
            let token = document.cookie.split("token-booking=")[1];
            let route_url = "";
            let unauth_url = "unauthorized/";

            if (token !== undefined) {
                // if booking token is found set user as logged in
                token = token.split(";")[0];
            }

            if (
                token === undefined &&
                url !== "signin"
            ) {
                route_url = route_url.concat(unauth_url, url); // adds unauth prefix to route
            } else {
                route_url = route_url.concat(url); // adds route without unauth prefix
            }

            const splitted_url = route_url.split("/");


            let route = "";

            for (let i = 0; i < splitted_url.length; i++) {
                let isnum = /^\d+$/.test(splitted_url[i]);
                if (!isnum) route = route + (i !== 0 ? "_" : "") + splitted_url[i];
            }

            axios({
                method,
                url: BASE_URL + route_url,
                signal,
                data: body,
                headers: {
                    Authorization: "Bearer " + token,
                },
            })
                .then((res) => {
                    const { data } = res || {}; // Safe destructuring
                    resolve(data);
                })
                .catch((err) => {
                    console.error(err.response);
                    if (url !== "signin" && (err.response.status === 401 || err.response.status === 403 || err.response.status === 404)) {
                        document.cookie =
                            "token-booking=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
                        alert("Istuntosi on vanhentunut, kirjaudu uudelleen sisään.");
                        navigate("/login");
                    }
                    else {
                        reject(err);
                    }

                });
        });
    };

    return (
        <ApiContext.Provider
            value={{
                ApiCall,
            }}>
            {children}
        </ApiContext.Provider>
    );
};