import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Box, FormControl, IconButton, InputLabel, Select, MenuItem, Alert, DialogContentText, Autocomplete } from '@mui/material';
import { useUser } from '../../Utils/UserContext';
import ColorScheme from '../../Styles/ColorScheme';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ReactComponent as RemoveIcon } from "../../Assets/remove_icon.svg";
import OPERATORS from "./OperatorList.json";
import dayjs from 'dayjs';
import 'dayjs/locale/fi'
dayjs.locale('fi');



const CalendarPaperStyle = {
    borderRadius: "10px",
    overflow: "hidden",
    "& .MuiPickersDay-daySelected": {
        backgroundColor: ColorScheme.primary,
        color: ColorScheme.white,
        fontWeight: 700,
    },
    "& .Mui-selected": {
        backgroundColor: ColorScheme.primary,
        color: ColorScheme.white,
    },
    "& .MuiPickersCalendarHeader-label": {
        color: ColorScheme.black,
        fontWeight: 700,
        textTransform: "capitalize",
    },
    "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected": {
        backgroundColor: ColorScheme.primary,
    },

};

const ContractDialog = ({ open, onClose, contract, onSave }) => {

    const { user } = useUser();
    const [customers, setCustomers] = useState([]);

    const [formData, setFormData] = useState({
        startingDate: '',
        endingDate: '',
        nextInvoicesDate: '',
        description: '',
        customer: {
            customerName: '',
            postalCode: '',
            city: '',
            address: '',
            businessId: '',
            invoiceIdentifier: '',
            invoiceReference: '',
            invoiceOperator: '',
            contactName: '',
            phone: '',
            email: '',
            additionalInformation: '',
            organizationIdentifier: ''
        },
        rows: []
    });

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [endingTheContract, setEndingTheContract] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleConfirmOpen = () => {
        setConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const handleTerminate = () => {
        setEndingTheContract(true);
        setConfirmOpen(false);
        updateInvoiceDate(formData.startingDate, true);
    };

    useEffect(() => {
        if (!open) return;
        setEndingTheContract(false);
        setShowError(false);
        const fetchCustomers = async () => {
            try {
                const customers = await user.getCustomers();
                return customers;
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };
        const fetchRowData = async (contract) => {
            try {
                const rows = await contract.getRows();
                return rows;
            } catch (error) {
                console.error('Error fetching row data:', error);
                return [];
            }
        };
        fetchCustomers().then((customers) => {
            setCustomers(customers.customers);
        });
        if (contract) {
            fetchRowData(contract).then((rows) => {
                setFormData({
                    ...contract,
                    rows: rows
                });
            });
        } else {
            setFormData({
                startingDate: '',
                endingDate: '',
                nextInvoicesDate: '',
                description: '',
                customer: {
                    id: '',
                    customerName: '',
                    postalCode: '',
                    city: '',
                    address: '',
                    businessId: '',
                    invoiceIdentifier: '',
                    invoiceReference: '',
                    invoiceOperator: '',
                    contactName: '',
                    phone: '',
                    email: '',
                    additionalInformation: '',
                    organizationIdentifier: ''
                },
                rows: []
            });
        }
    }, [contract, open]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name in formData.customer) {
            setFormData((prevData) => ({
                ...prevData,
                customer: {
                    ...prevData.customer,
                    [name]: value
                }
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleRowChange = (index, e) => {
        const { name, value } = e.target;
        const updatedRows = formData.rows.map((row, i) => {
            if (i === index) {
                const updatedRow = { ...row, [name]: value };
                return recalculatePrices(updatedRow);
            }
            return row;
        });
        setFormData((prevData) => ({
            ...prevData,
            rows: updatedRows
        }));
    };

    const handleUnitPriceChange = (index, e) => {
        const { value } = e.target;
        const updatedRows = formData.rows.map((row, i) => {
            if (i === index) {
                const amount = parseFloat(row.amount) || 0;
                const discountPercentage = parseFloat(row.discountPercentage) || 0;
                const unitPrice = parseFloat(value) || 0;
                const price = unitPrice * amount * (1 - discountPercentage / 100);
                return { ...row, unitPrice: value, price: price.toFixed(2) };
            }
            return row;
        });
        setFormData((prevData) => ({
            ...prevData,
            rows: updatedRows
        }));
    };

    const handlePriceChange = (index, e) => {
        const { value } = e.target;
        const updatedRows = formData.rows.map((row, i) => {
            if (i === index) {
                const amount = parseFloat(row.amount) || 0;
                const discountPercentage = parseFloat(row.discountPercentage) || 0;
                const price = parseFloat(value) || 0;
                const unitPrice = price / (amount * (1 - discountPercentage / 100));
                return { ...row, price: value, unitPrice: unitPrice.toFixed(2) };
            }
            return row;
        });
        setFormData((prevData) => ({
            ...prevData,
            rows: updatedRows
        }));
    };

    const handleBlur = (index, field) => {
        const updatedRows = formData.rows.map((row, i) => {
            if (i === index) {
                const value = parseFloat(row[field]) || 0;
                return { ...row, [field]: value.toFixed(2) };
            }
            return row;
        });
        setFormData((prevData) => ({
            ...prevData,
            rows: updatedRows
        }));
    };

    const recalculatePrices = (row) => {
        const amount = parseFloat(row.amount) || 0;
        const discountPercentage = parseFloat(row.discountPercentage) || 0;
        const unitPrice = parseFloat(row.unitPrice) || 0;
        const price = unitPrice * amount * (1 - discountPercentage / 100);
        return { ...row, price: price.toFixed(2) };
    };

    const addRow = () => {
        setFormData((prevData) => ({
            ...prevData,
            rows: [...prevData.rows, { description: '', amount: '', unit: 'kk', unitPrice: '', discountPercentage: '', price: '', vatPercentage: 25.5 }]
        }));
    };

    const removeRow = (index) => {
        setFormData((prevData) => ({
            ...prevData,
            rows: prevData.rows.filter((_, i) => i !== index)
        }));
    };

    const handleSubmit = async () => {
        try {
            // Convert amount, price, and unitPrice to numbers
            const formDataToSend = {
                ...formData,
                rows: formData.rows.map(row => ({
                    ...row,
                    amount: row.amount ? parseFloat(row.amount) : 0,
                    price: row.price ? parseFloat(row.price) : 0,
                    unitPrice: row.unitPrice ? parseFloat(row.unitPrice) : 0,
                    vatPercentage: row.vatPercentage ? parseFloat(row.vatPercentage) : 0,
                    discountPercentage: row.discountPercentage ? parseFloat(row.discountPercentage) : 0
                }))
            };

            if (contract) {
                await user.modifyContract(contract.id, formDataToSend);
            } else {
                await user.addContract(formDataToSend);
            }
            onSave();
            onClose();
        } catch (error) {
            setShowError(true);
            console.error('Error saving contract:', error);
        }
    };


    const updateInvoiceDate = async (start, contractEnding) => {
        // Filter formData.rows to get rows with unit "kk"
        const contractRows = formData.rows.filter(row => row.unit === 'kk');
        const highestAmount = contractRows.reduce((max, row) => row.amount > max ? row.amount : max, 0);

        if (contractEnding) {
            contract.getLatestInvoice().then((invoice) => {
                if (invoice) {
                    setFormData((prevData) => ({
                        ...prevData,
                        nextInvoicesDate: invoice.getDueDate().toISOString()
                    }));
                    const endingDate = dayjs(invoice.getDueDate()).add(highestAmount, 'month').endOf('month').toISOString();
                    setFormData((prevData) => ({
                        ...prevData,
                        endingDate: endingDate
                    }));
                } else {
                    const nextInvoicingDate = dayjs(start).add(1, 'month').startOf('month');
                    setFormData((prevData) => ({
                        ...prevData,
                        nextInvoicesDate: nextInvoicingDate.toISOString()
                    }));
                    const endingDate = dayjs(start).add(highestAmount, 'month').endOf('month').toISOString();
                    setFormData((prevData) => ({
                        ...prevData,
                        endingDate: endingDate
                    }));
                }
            });
        } else {
            if (dayjs(start).date() === 1) {
                const nextInvoicingDate = dayjs(start).startOf('month');
                setFormData((prevData) => ({
                    ...prevData,
                    nextInvoicesDate: nextInvoicingDate.toISOString()
                }));
            } else {
                const nextInvoicingDate = dayjs(start).add(1, 'month').startOf('month');
                setFormData((prevData) => ({
                    ...prevData,
                    nextInvoicesDate: nextInvoicingDate.toISOString()
                }));
            }
        }
    };

    const formattedStartingDate = dayjs(formData.startingDate);
    const formattedNextInvoicesDate = formData.nextInvoicesDate ? dayjs(formData.nextInvoicesDate) : null;


    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs">

            <Box sx={{ flexDirection: "row", display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: ColorScheme.white }}>
                <DialogTitle variant='h2'>
                    {contract ? 'Muokkaa sopimusta' : 'Lisää uusi sopimus'}
                </DialogTitle>
                {contract && <Button disabled={endingTheContract || (contract && contract.getEndingDate() !== null)} onClick={handleConfirmOpen} variant="contained" color="primary" sx={{ backgroundColor: ColorScheme.red, margin: "20px", }}>
                    <Typography color={ColorScheme.white} textTransform={"none"} variant='h6'>
                        {!endingTheContract ? (contract && contract.getEndingDate() !== null ? "Sopimus on irtisanottu" : "Irtisano sopimus") : "Sopimusta ollaan irtisanomassa"}
                    </Typography>
                </Button>}
                <Dialog
                    open={confirmOpen}
                    onClose={handleConfirmClose}
                    aria-labelledby="confirm-dialog-title"
                    aria-describedby="confirm-dialog-description"
                >
                    <DialogTitle variant='h4' id="confirm-dialog-title">{"Vahvista sopimuksen irtisanominen"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="confirm-dialog-description">
                            {"Oletko varma, että haluat irtisanoa tämän sopimuksen? Tätä toimintoa ei voi peruuttaa."}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmClose} color="primary">
                            <Typography color={ColorScheme.primary} textTransform={"none"} variant='h6'>
                                {"Peruuta"}
                            </Typography>
                        </Button>
                        <Button onClick={handleTerminate} color="primary" autoFocus>
                            <Typography color={ColorScheme.red} textTransform={"none"} variant='h6'>
                                {"Irtisano sopimus"}
                            </Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            {contract && contract.getEndingDate() !== null && <Alert sx={{ margin: "20px", alignItems: "center" }} severity="error">
                <Typography variant='h6'> {'Huom! Sopimus on irtisanottu. Sen viimeinen voimassaolopäivä on ' + new Date(formData.endingDate).toLocaleDateString()} </Typography>
            </Alert>}
            {contract && endingTheContract && <Alert sx={{ margin: "20px", alignItems: "center" }} severity="warning">
                <Typography variant='h6'> {'Huom! Sopimukselle on asetettu päättymispäivämäärä. ' + 'Sopimus päättyy: ' + new Date(formData.endingDate).toLocaleDateString() + '. Tallenna muokkaukset, jotta irtisanominen tulee voimaan!'} </Typography>
            </Alert>}
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi" localeText={{ cancelButtonLabel: "Peruuta", okButtonLabel: "Valitse", clearButtonLabel: "example" }}>
                <DialogContent className="hide-scrollbar" sx={{ maxHeight: '80vh' }}>
                    <FormControl sx={{ flexDirection: "row", display: "flex", backgroundColor: ColorScheme.white, width: "100%", justifyContent: "space-between" }} size="small" margin="dense">
                        <DatePicker
                            sx={{ width: "48%" }}
                            label={contract ? "Sopimus alkoi" : "Sopimus alkaa"}
                            value={formattedStartingDate}
                            onChange={(date) => {
                                setFormData((prevData) => ({ ...prevData, startingDate: date.toISOString() }))
                                updateInvoiceDate(date, false);
                            }}
                            slotProps={{
                                textField: {
                                    size: "small",
                                },
                                desktopPaper: {
                                    sx: CalendarPaperStyle,
                                },
                                mobilePaper: {
                                    sx: CalendarPaperStyle,
                                },
                                toolbar: {
                                    title: "Valittu päivä",
                                    sx: {
                                        textTransform: "none",
                                    },
                                },
                                actionBar: {
                                    sx: {
                                        "& .MuiButton-root": {
                                            textTransform: "none",
                                            color: ColorScheme.primary,
                                        },
                                    },

                                },
                            }}
                        />
                        <DatePicker
                            sx={{ width: "48%" }}
                            label={formattedNextInvoicesDate < new Date() ? (new Date(formData.startingDate) !== "" ? "Viimeinen laskutuspäivä" : "Seuraava laskutuspäivä") : "Seuraava laskutuspäivä"}
                            value={formattedNextInvoicesDate}
                            disabled
                            slotProps={{
                                textField: {
                                    size: "small",
                                },
                                desktopPaper: {
                                    sx: CalendarPaperStyle,
                                }
                            }}
                        />
                    </FormControl>
                    <TextField
                        size='small'
                        margin="dense"
                        label="Sopimuksen kuvaus"
                        name="description"
                        value={formData.description}
                        onChange={(e) => handleChange(e)}
                        fullWidth
                        multiline
                        rows={4}
                    />
                    <Typography variant='h4' sx={{ my: "10px" }}>{formData.customer.id ? ("Muokataan asiakasta, jonka ID on  " + formData.customer.id) : "Luodaan uutta asiakasta"}</Typography>
                    <Autocomplete
                        options={customers && customers}
                        getOptionLabel={(option) => option.customerName}
                        renderInput={(params) => <TextField {...params} label="Valitse asiakas" margin='dense' size='small' variant="outlined" />}
                        value={formData.customer}
                        onChange={(event, newValue) => {
                            if (!newValue) {
                                setFormData((prevData) => ({
                                    ...prevData,
                                    customer: {
                                        id: '',
                                        customerName: '',
                                        postalCode: '',
                                        city: '',
                                        address: '',
                                        businessId: '',
                                        invoiceIdentifier: '',
                                        invoiceReference: '',
                                        invoiceOperator: '',
                                        contactName: '',
                                        phone: '',
                                        email: '',
                                        additionalInformation: '',
                                        organizationIdentifier: ''
                                    }
                                }));
                            }
                            else {
                                console.log(newValue);
                                setFormData((prevData) => ({
                                    ...prevData,
                                    customer: newValue
                                }));
                            }

                        }}
                        fullWidth
                    />
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <TextField sx={{ width: "48%" }} size='small' margin="dense" label="Asiakkaan nimi" name="customerName" value={formData.customer.customerName} onChange={(e) => handleChange(e)} />
                        <TextField sx={{ width: "48%" }} size='small' margin="dense" label="Y-tunnus" name="businessId" value={formData.customer.businessId} onChange={(e) => handleChange(e)} />
                    </Box>

                    <TextField size='small' margin="dense" label="Osoite" name="address" value={formData.customer.address} onChange={(e) => handleChange(e)} fullWidth />
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <TextField sx={{ width: "48%" }} size='small' margin="dense" label="Postinumero" name="postalCode" value={formData.customer.postalCode} onChange={(e) => handleChange(e)} />
                        <TextField sx={{ width: "48%" }} size='small' margin="dense" label="Kaupunki" name="city" value={formData.customer.city} onChange={(e) => handleChange(e)} />
                    </Box>
                    <TextField size='small' margin="dense" label="Lisätiedot" name="additionalInformation" value={formData.customer.additionalInformation ? formData.customer.additionalInformation : ""} onChange={(e) => handleChange(e)} fullWidth />

                    <Typography variant='h4' sx={{ my: "10px" }}>{"Laskutustiedot"}</Typography>
                    <TextField size='small' margin="dense" label="Organisaatiotunnus" name="organizationIdentifier" value={formData.customer.organizationIdentifier ? formData.customer.organizationIdentifier : ""} onChange={(e) => handleChange(e)} fullWidth />
                    <TextField size='small' margin="dense" label="Verkkolaskuosoite" name="invoiceIdentifier" value={formData.customer.invoiceIdentifier} onChange={(e) => handleChange(e)} fullWidth />
                    <TextField size='small' margin="dense" label="Verkkolaskuviite" name="invoiceReference" value={formData.customer.invoiceReference} onChange={(e) => handleChange(e)} fullWidth />
                    <Autocomplete
                        value={OPERATORS.find(operator => operator.broker_id === formData.customer.invoiceOperator) || null}
                        options={OPERATORS.sort((a, b) => a.name.localeCompare(b.name))}
                        getOptionLabel={(option) => `${option.broker_id} - ${option.name}`}
                        onChange={(event, value) => {
                            handleChange({ target: { name: 'invoiceOperator', value: value ? value.broker_id : '' } });
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin='dense'
                                fullWidth
                                name='invoiceOperator'
                                size="small"
                                label="Verkkolaskuoperaattori"
                            />
                        )}
                    />

                    <Typography variant='h4' sx={{ my: "10px" }}>{"Yhteyshenkilö"}</Typography>
                    <TextField size='small' margin="dense" label="Yhteyshenkilö" name="contactName" value={formData.customer.contactName} onChange={(e) => handleChange(e)} fullWidth />
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <TextField sx={{ width: "48%" }} size='small' margin="dense" label="Puhelinnumero" name="phone" value={formData.customer.phone} onChange={(e) => handleChange(e)} />
                        <TextField sx={{ width: "48%" }} size='small' margin="dense" label="Sähköpostiosoite" name="email" value={formData.customer.email} onChange={(e) => handleChange(e)} />
                    </Box>

                    <Typography variant='h4' sx={{ my: "10px" }}>{"Sopimuksen rivit"}</Typography>
                    {formData.rows.map((row, index) => (
                        <Box sx={{ backgroundColor: ColorScheme.lightGrey, width: "100%", marginBottom: "10px", borderRadius: "4px", border: "1px solid", borderColor: ColorScheme.border, px: "10px", paddingTop: "5px", paddingBottom: "8px", position: "relative" }} key={index}>
                            <IconButton
                                sx={{
                                    padding: 0,
                                    minWidth: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "20px",
                                    width: "20px",
                                    position: "absolute",
                                    top: "-10px",
                                    right: "-10px",
                                    backgroundColor: ColorScheme.red,
                                    color: ColorScheme.white,
                                    borderRadius: "50%",
                                    zIndex: 1
                                }}
                                onClick={() => removeRow(index)}>
                                <RemoveIcon height={"18px"} stroke={ColorScheme.white} />
                            </IconButton>
                            <TextField sx={{ backgroundColor: ColorScheme.white }} size='small' margin="dense" label="Rivin kuvaus" name="description" value={row.description} onChange={(e) => handleRowChange(index, e)} fullWidth />

                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <TextField sx={{ backgroundColor: ColorScheme.white, width: "15%" }} size='small' margin="dense" label="Määrä" name="amount" value={row.amount} onChange={(e) => handleRowChange(index, e)} />
                                <FormControl sx={{ backgroundColor: ColorScheme.white, width: "20%" }} size="small" margin="dense">
                                    <InputLabel id={`unit-label-${index}`}>{"Yksikkö"}</InputLabel>
                                    <Select
                                        labelId={`unit-label-${index}`}
                                        id={`unit-select-${index}`}
                                        label="Yksikkö"
                                        name="unit"
                                        value={row.unit}
                                        onChange={(e) => handleRowChange(index, e)}>
                                        <MenuItem value="kk">kk</MenuItem>
                                        <MenuItem value="kpl">kpl</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField sx={{ width: "28%", backgroundColor: ColorScheme.white }} size='small' margin="dense" label="À-hinta" name="unitPrice" value={row.unitPrice} onChange={(e) => handleUnitPriceChange(index, e)} onBlur={() => handleBlur(index, 'unitPrice')} />
                                <TextField sx={{ width: "28%", backgroundColor: ColorScheme.white }} size='small' margin="dense" label="Rivin hinta" name="price" value={row.price} onChange={(e) => handlePriceChange(index, e)} onBlur={() => handleBlur(index, 'price')} />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <TextField sx={{ width: "48%", backgroundColor: ColorScheme.white }} size='small' margin="dense" label="Alennusprosentti" name="discountPercentage" value={row.discountPercentage} onChange={(e) => handleRowChange(index, e)} />
                                <FormControl sx={{ backgroundColor: ColorScheme.white, width: "48%" }} size="small" margin="dense">
                                    <InputLabel id={`unit-label-${index}`}>{"Alv-prosentti"}</InputLabel>
                                    <Select
                                        labelId={`unit-label-${index}`}
                                        id={`unit-select-${index}`}
                                        label="Alv-prosentti"
                                        name="vatPercentage"
                                        value={parseFloat(row.vatPercentage)}
                                        onChange={(e) => handleRowChange(index, e)}
                                    >
                                        <MenuItem value={0}>0 %</MenuItem>
                                        <MenuItem value={10}>10 %</MenuItem>
                                        <MenuItem value={14}>14 %</MenuItem>
                                        <MenuItem value={24}>24 %</MenuItem>
                                        <MenuItem value={25.5}>25,5 %</MenuItem>

                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    ))}

                    <Button fullWidth onClick={addRow} variant='outlined' color="primary" sx={{ backgroundColor: ColorScheme.primary, borderColor: ColorScheme.primary }}>
                        <Typography color={ColorScheme.white} textTransform={"none"} variant='h6'>
                            {"Lisää uusi rivi"}
                        </Typography>
                    </Button>

                </DialogContent>
            </LocalizationProvider>

            <DialogActions sx={{ width: "100%", justifyContent: "space-between" }}>
                <Box sx={{ width: "50%" }}>
                    {showError && <Alert severity="error" sx={{ width: "100%", alignItems: "center" }}>
                        <Typography variant='h6'> {"Täytä kaikki kentät!"} </Typography>
                    </Alert>}
                </Box>

                <Box>
                    <Button onClick={onClose} variant='outlined' color="primary" sx={{ marginRight: "10px", borderRadius: "4px", borderWidth: "1px", borderColor: ColorScheme.primary }}>
                        <Typography color={ColorScheme.primary} textTransform={"none"} variant='h6'>
                            {"Peruuta"}
                        </Typography>
                    </Button>
                    {(!contract || (contract && !contract.getEndingDate())) && <Button onClick={handleSubmit} variant='outlined' color="primary" sx={{ backgroundColor: ColorScheme.primary, borderColor: ColorScheme.primary }}>
                        <Typography color={ColorScheme.white} textTransform={"none"} variant='h6'>
                            {contract ? "Tallenna muokkaukset" : "Lisää sopimus"}
                        </Typography>
                    </Button>}
                </Box>

            </DialogActions>

        </Dialog>
    );
};

export default ContractDialog;
