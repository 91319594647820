import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, ResponsiveContainer } from 'recharts';
import ColorScheme from '../../Styles/ColorScheme';
import { useUser } from '../../Utils/UserContext';

const getLastSixMonths = () => {
    const months = ['Tammi', 'Helmi', 'Maalis', 'Huhti', 'Touko', 'Kesä', 'Heinä', 'Elo', 'Syys', 'Loka', 'Marras', 'Joulu'];
    const result = [];
    const date = new Date();
    for (let i = 0; i < 6; i++) {
        result.unshift(months[date.getMonth()]);
        date.setMonth(date.getMonth() - 1);
    }
    return result;
};

const MainView = () => {
    const { user, loading } = useUser();
    const [lineData, setLineData] = useState([]);
    const [barData, setBarData] = useState([]);

    useEffect(() => {
        user.getStatistics().then(statistics => {
            const { contractCount, customerCount, invoiceCount } = statistics;
            const { paid, unpaid } = invoiceCount;

            const finnishMonths = getLastSixMonths();

            // Reverse the fetched values
            const reversedContractCount = contractCount.reverse();
            const reversedCustomerCount = customerCount.reverse();
            const reversedPaid = paid.reverse();
            const reversedUnpaid = unpaid.reverse();
            console.log(reversedContractCount, reversedCustomerCount, reversedPaid, reversedUnpaid);

            const lineData = finnishMonths.map((month, index) => ({
                name: month,
                Sopimukset: reversedContractCount[index],
                Asiakkaat: reversedCustomerCount[index]
            }));

            const barData = finnishMonths.map((month, index) => ({
                name: month,
                Maksettu: reversedPaid[index],
                Maksamatta: reversedUnpaid[index]
            }));

            setLineData(lineData);
            setBarData(barData);
        });
    }, []);

    return (
        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between", height: "calc(100vh - 150px)" }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '48%', height: 'calc(50vh - 100px)' }}>
                    <Typography sx={{ marginBottom: "20px", textAlign: "center" }} variant="h2">Sopimukset</Typography>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={lineData} margin={{ right: 30, left: 10 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Sopimukset" stroke={ColorScheme.primary} />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
                <Box sx={{ width: '48%', height: 'calc(50vh - 100px)' }}>
                    <Typography sx={{ marginBottom: "20px", textAlign: "center" }} variant="h2">Asiakkaat</Typography>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={lineData} margin={{ right: 30, left: 10 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Asiakkaat" stroke={ColorScheme.primary} />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
            <Box sx={{ width: '100%', height: 'calc(50vh - 100px)' }}>
                <Typography sx={{ marginBottom: "20px", textAlign: "center" }} variant="h2">Lähetetyt laskut</Typography>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={barData} margin={{ right: 30, left: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Maksettu" fill={ColorScheme.green} />
                        <Bar dataKey="Maksamatta" fill={ColorScheme.red} />
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};

export default MainView;