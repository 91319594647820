// User.js

import Contract from './Contract'; // Import the Contract model
import Customer from './Customer';

class User {
    constructor(data, ApiCall) {
        this.id = data.id;
        this.email = data.email;
        this.password = data.password;
        this.latestActivity = data.latestActivity ? new Date(data.latestActivity) : null;
        this.createdAt = new Date(data.createdAt);
        this.updatedAt = new Date(data.updatedAt);
        this.twoFactorEnabled = data.twoFactorEnabled;
        this.ApiCall = ApiCall; // Store the ApiCall function
    }

    getId() {
        return this.id;
    }

    getEmail() {
        return this.email;
    }

    getPassword() {
        return this.password;
    }

    getLatestActivity() {
        return this.latestActivity;
    }

    getCreatedAt() {
        return this.createdAt.toLocaleString();
    }

    getUpdatedAt() {
        return this.updatedAt.toLocaleString();
    }

    async getContracts({ sortField, sortOrder, searchTerm, page, limit }) {
        try {
            const response = await this.ApiCall("GET", `contracts?sortField=${sortField}&sortOrder=${sortOrder}&searchTerm=${searchTerm}&page=${page}&limit=${limit}`, null);
            const contracts = await Promise.all(response.contracts.map(async contractData => {
                const contract = new Contract(contractData, this.ApiCall);
                return contract;
            }));
            return {
                contracts,
                totalPages: response.totalPages
            };
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getContractById(contractId) {
        try {
            const response = await this.ApiCall("GET", `contracts/${contractId}`, null);
            const contract = new Contract(response, this.ApiCall);
            return contract;
        } catch (error) {
            console.error(`Failed to fetch contract with ID ${contractId}:`, error);
            throw error;
        }
    }

    async getCustomers() {
        try {
            const response = await this.ApiCall("GET", `customers`, null);
            const customers = await Promise.all(response.map(async customerData => {
                const customer = new Customer(customerData);
                return customer;
            }));
            return {
                customers
            };
        } catch (error) {
            console.error('Failed to fetch customers:', error);
            throw error;
        }
    }

    saveToLocalStorage() {
        localStorage.setItem('user', JSON.stringify({
            id: this.id,
            name: this.name,
            email: this.email,
            token: this.token
        }));
    }

    static loadFromLocalStorage(ApiCall) {
        const userData = localStorage.getItem('user');
        return userData ? new User(JSON.parse(userData), ApiCall) : null;
    }

    static clearLocalStorage() {
        localStorage.removeItem('user');
    }

    getToken() {
        return this.token;
    }

    getTwoFactorEnabled() {
        return this.twoFactorEnabled;
    }

    async getUsers() {
        try {
            const response = await this.ApiCall("GET", `users`, null);
            const users = await Promise.all(response.map(async userData => {
                const user = new User(userData, this.ApiCall);
                return user;
            }
            ));
            return {
                users
            };
        } catch (error) {
            console.error('Failed to fetch users:', error);
            throw error;
        }
    }

    async createUser(data) {
        try {
            const response = await this.ApiCall("POST", `users`, data);
            return response;
        } catch (error) {
            console.error('Failed to create user:', error);
            throw error;
        }
    }

    async changePassword(userId, data) {
        try {
            const response = await this.ApiCall("PUT", `users/${userId}`, data);
            return response;
        } catch (error) {
            console.error('Failed to change password:', error);
            throw error;
        }
    }

    async addContract(data) {
        try {
            const dataArray = Array.isArray(data) ? data : [data];
            const response = await this.ApiCall("POST", `contracts`, dataArray);
            return response;
        } catch (error) {
            console.error('Failed to add contract:', error);
            throw error;
        }
    }

    async modifyContract(contractId, data) {
        try {
            const response = await this.ApiCall("PUT", `contracts/${contractId}`, data);
            return response;
        } catch (error) {
            console.error('Failed to modify contract:', error);
            throw error;
        }
    }

    async getStatistics() {
        try {
            const response = await this.ApiCall("GET", `statistics`, null);
            return response;
        } catch (error) {
            console.error('Failed to fetch statistics:', error);
            throw error;
        }
    }
}

export default User;